import "./App.css";
import KPSlogo from "./KPSlogo.jpg";

function App() {
  return (
    <div className="App">
      <img src={KPSlogo} alt="kps logo" />
      <h1>KPS Technologies</h1>
      <h3>Coming Soon..</h3>
    </div>
  );
}

export default App;
